export default {
    name: "Glossary",
    created() {
        this.$nextTick(function() {
            this.relevantData('A', 0)
            this.GitGlossarySeek(this.GlossaryData[0])
            this.ariseCombobox('#comboboxGlossary')
        });
    },
    mounted() {
        this.$nextTick(function() {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            GlossaryShwoData: [],
            GlossaryData: [
                [{
                        letter: 'A',
                        data: [{
                                name: `ALPHA`,
                                content: `Alpha is a measure of the performance of an investment as compared to a suitable benchmark index. An alpha of one (the baseline value is zero) shows that the return on the investment during a specified time frame outperformed the overall market average by 1%. A negative alpha number reflects an investment that is underperforming as compared to the market average.`
                            },
                            {
                                name: `AMERICAN-STYLE`,
                                content: `A DW that may be exercised on any market day up to and including the maturity date. `
                            },
                            {
                                name: `AT-THE-MONEY (ATM)`,
                                content: `A situation where the DW's exercise price is close to or identical to the underlying asset price. An at-the-money DW has no intrinsic value, but may still have some time value (depends on time to maturity).<br/>For example if XYZ stock is trading at THB 2.00, then the call DW or put DW with the exercise price of THB 2.00 are said to be at-the-money. `
                            }
                        ]
                    },
                    {
                        letter: 'B',
                        data: [{
                                name: `BEAR MARKET`,
                                content: `A stock market in which sellers dominate, resulting in generally falling prices.`
                            },
                            {
                                name: `BID VOLUME`,
                                content: `Bid volume is the quantity of a security that buyers are willing to buy at a specified bid price. Bid volume is stated as board lots with each representing 100 units (shares) each.`
                            },
                            {
                                name: 'BID PRICE',
                                content: 'Bid price is the highest price a buyer is willing to purchase a particular security. '
                            },
                            {
                                name: 'BLACK-SCHOLES MODEL',
                                content: 'One of the most commonly used option pricing models.'
                            },
                            {
                                name: 'BOARD LOT',
                                content: 'Standardardized trading unit defined by the exchange. For the Securities Exchange of Thailand (SET), one (1) board lot is equivalent to 100 units of security. However, for a security with closing price of THB 500.00 and above for six (6) consecutive months, one (1) board lot is equivalent to 50 units of security.'
                            },
                            {
                                name: 'BONUS ISSUE',
                                content: 'Bonus Issues are shares distributed by a company to its current shareholder as fully paid shares free of charge. Instead of a dividend payout, companies offer to distribute additional shares to the shareholder. For example, the company may decide to give out one bonus share for every ten shares held by existing shareholder. '
                            },
                            {
                                name: 'BROKER',
                                content: 'The securities company authorised to be the agent to buy and sell securities on behalf of an investor.'
                            },
                            {
                                name: 'BULL MARKET',
                                content: 'A stock market in which buyers dominate and where prices are on a rising trend.'
                            },
                            {
                                name: 'BREAK-EVEN LEVEL',
                                content: 'Refers to the price at which a transaction produces neither a gain nor a loss.'
                            }

                        ]
                    },
                    {
                        letter: 'C',
                        data: [{
                                name: 'CALL DW',
                                content: 'A call DW offers its holder the right, but not obligation to purchase a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.'
                            },
                            {
                                name: 'CALLABLE BULL/BEAR CONTRACTS (CBBC)',
                                content: `CBBC tracks the performance of an underlying stock without requiring investors to pay the full price required to own the actual stock. They are issued either as Bull or Bear certificates with a fixed maturity, allowing investors to take bullish or bearish positions on the underlying stock with the possibility of an early termination before maturity when the underlying moves in contrary direction to investors' expectations.`
                            },
                            {
                                name: `CASH SETTLEMENT AMOUNT`,
                                content: `The positive amount received from the exercise of the DW at maturity. In the event the Cash Settlement Amount is less than or equal to zero, DW holder shall not be entitled to receive any payment in respect of the DW.`
                            }
                        ]
                    },
                    {
                        letter: 'D',
                        data: [{
                                name: `DELTA`,
                                content: `Delta is the ratio of the change in the DW price to the change in the underlying asset price. It shows the absolute changes in the price of the DW, if the price of the underlying asset changes.<br/><br/> Delta normally ranges between 0 and 1 for call DW and 0 and -1 for put DW. For example (assuming a DW with conversion ratio of 1) a delta of 0.50 implies that if the value of the underlying changes by THB 0.20, then the value of the call DW should change by THB 0.10.`
                            },
                            {
                                name: `DISCOUNT`,
                                content: `The amount by which the price of an asset is quoted below its Par Value.`
                            },
                            {
                                name: `DIVIDEND YIELD`,
                                content: `The amount of a company's annual dividend expressed as a percentage of the current price of the share of that company.`
                            },
                            {
                                name: `DAY'S HIGH/ HIGH PRICE`,
                                content: `High price refers to a security's intraday high trading price. High price indicates the stock's highest price traded throughout one day of trading activity during regular trading hours. `
                            },
                            {
                                name: `DW ISSUER`,
                                content: `The entity that issues DW, usually a financial institution, e.g. Maybank Securities (Thailand) Plc.`
                            },
                            {
                                name: `DW LIQUIDITY`,
                                content: `A DW market is considered as liquid when there is:-<br/><br/>i. Adequate bid and offer volume; and/ or<br/>ii. Sufficient depth in bid and offer price.`
                            },
                            {
                                name: `DERIVATIVE WARRANTS ("DW")`,
                                content: `A DW offers its holder the right, but not obligation to purchase or sell a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.`
                            },
                            {
                                name: `DW CALCULATOR`,
                                content: `DW calculator tool allows investors to gauge the theoretical price of a specific DW based on parameters such as underlying asset price, days to maturity and/or implied volatility.<br/><br/>Investors can gauge the price of the DW based on their own perceived view of the movement in the underlying asset price and/or implied volatility while factoring in holding period of the DW.`
                            },
                            {
                                name: `DW TRADING HOURS`,
                                content: `DW trading hours for SET index and Thai shares are 1) First Session from 9.55am-10.00am to 12.30pm 2) Second Session from 2.25pm-2.30pm to 4.35pm-4.40pm. `
                            },
                            {
                                name: `DW HOLDER`,
                                content: `The name that appear in the Record of Thailand Securities Depository Co., Ltd. (TSD) for the DW.`
                            }, {
                                name: `DW PROSPECTUS`,
                                content: `The document issued by an DW issuer in connection with the offering of DW to be issued from time to time.`
                            }
                        ]
                    },
                    {
                        letter: 'E',
                        data: [{
                                name: `EARNINGS PER SHARE`,
                                content: `The amount of a company's annual profits or earning attributable to each ordinary share of that company.`
                            },
                            {
                                name: `EFFECTIVE GEARING`,
                                content: `Measures the theoretical percentage change in DW price for every 1% change in the underlying asset price. For example, effective gearing of 10 times, other factors being equal, means for every 1% change in underlying asset price, the DW price moves by 10%.`
                            },
                            {
                                name: `EXCHANGE TRADING HOURS`,
                                content: `The equities trading hours are divided into two sessions for both normal and odd lot market.<br/>For Malaysia: The first session is from 8.30am–12.30pm, and the second session runs from 2.00pm–5.00pm.<br/>For Hong Kong: The first session is from 9:00am-12:00pm, and the second session runs from 1:00pm-4:00pm.<br/>For Thailand: The first session open at random between 9:55 to 10:00am-12:30pm, and the second session open at random between 2:25 to 2:30pm and closes at random between 4:35 to 4:40pm.`
                            },
                            {
                                name: `EUROPEAN-STYLE`,
                                content: `A DW that is automatically exercised on the predetermined maturity date.`
                            },
                            {
                                name: `EXERCISE PRICE/LEVEL`,
                                content: `The predetermined price/level for the DW holder to buy or sell the underlying asset of the DW. It is used to calculate the cash settlement amount of a cash-settled DW at the maturity.`
                            },
                            {
                                name: `EXERCISE RATIO`,
                                content: `The number of DW needed to exchange for one unit of the underlying asset<br/><br/>For example, a XYZ call DW with an exercise ratio of 5 XYZ DWs : 1 XYZ share would indicate that five (5) XYZ DWs are needed to exercise the right to buy one (1) XYZ share at the exercise price.<br/>For DW linked to the price of shares, the ratio may be adjusted to reflect corporate actions, such as bonus issues, stock splits, rights issues, reverse stock split or special dividends.`
                            },
                            {
                                name: `EXCHANGE TRADED FUND (ETF)`,
                                content: `ETF is the acronym for an innovative financial product known as Exchange Traded Fund. It is an open-ended investment fund listed and traded on the exchange. ETF combines the features of an Index fund and a stock. <br/><br/>Generally, there are several types of ETFs: equity ETFs, Leveraged & Inverse ETFs, fixed income ETFs and commodity ETFs. These ETFs consists of baskets of stocks, bonds, futures or commodities based on an index which instantly offers broad diversification and avert the risk involved in owning stock of a single company.<br/><br/>The liquidity of an ETF reflects the liquidity of the basket of underlyings. `
                            }
                        ]
                    },
                    {
                        letter: 'F',
                        data: [{
                            name: `FUTURES`,
                            content: `Futures are financial contracts obligating the buyer to purchase the underlying asset or the seller to sell the underlying asset at a future date.`
                        }, {
                            name: `FIRST TRADING DAY`,
                            content: `The first day the DW is listed and quoted on the exchange, that investors can start trading DW.`
                        }]
                    },
                    {
                        letter: 'G',
                        data: [{
                                name: `GAMMA`,
                                content: `Measures the changes of a DW's delta as the price of the underlying asset changes, all other factors remain constant.`
                            },
                            {
                                name: `GEARING`,
                                content: `Gearing is the ratio of the underlying asset price to the DW price and represents the leverage that the DW offers.<br/><br/>For example, purchasing a unit of DW with gearing of 10x will give you 10 times more underlying asset exposure than if you purchased a unit of underlying asset. To estimate the increase or decrease in the DW price in relative to the underlying asset price, we should look at effective gearing.`
                            },
                            {
                                name: `GROWTH STOCKS`,
                                content: `Shares of companies with good prospects for increasing profits and capital size. Likely to bring shareholder future capital gains through a share price rise, high dividends, share bonuses or rights issues.`
                            },
                        ]
                    },
                    {
                        letter: 'H',
                        data: [{
                                name: `HEDGE`,
                                content: `Hedging is a strategy to reduce the risk of adverse price movements in an asset. Normally, a hedge consists of taking an offsetting position in a related security to protect the value of an asset or a portfolio.`
                            },
                            {
                                name: `HISTORICAL VOLATILITY`,
                                content: `Used as a reference to assess the previous price fluctuations of an asset. Normally expressed in number of days, e.g. 10-day volatility, 90-day volatility etc.`
                            }

                        ]
                    },
                    {
                        letter: 'I',
                        data: [{
                                name: `IMPLIED VOLATILITY`,
                                content: `Most important factor that affects the price of DW. It refers to the estimate of future price volatility of the underlying asset and is used by the market as an indicator to decide whether a DW is cheap or expensive. With all factors being equal, the higher the implied volatility, the higher the DW price.`
                            },
                            {
                                name: `INDICES`,
                                content: `A hypothetical portfolio of an asset class, security market or segment of a maket, where the index value is a numerical value calculated from the prices of the constituent components in the portfolio. Index value is often used by investors the gauge the price performance of the portfolio.`
                            },
                            {
                                name: `IN-THE-MONEY (ITM)`,
                                content: `An in-the-money DW has intrinsic value more than zero.<br/><br/>For call DW, it is said to be in-the-money if Underlying Asset Price > Exercise Price;<br/>For put DW, it is said to be in-the-money if Exercise Price > Underlying Asset Price.`
                            },
                            {
                                name: `INTRINSIC VALUE`,
                                content: `Intrinsic value for a DW is the difference between the price of the underlying asset and the exercise price. The intrinsic value will not be less than zero. `
                            },
                            {
                                name: `INTRADAY'S LOW/LOW PRICE`,
                                content: `Intraday Low (or Day Low) refers to the lowest price at which a security trades on a given trading day.`
                            }
                        ]
                    },
                    {
                        letter: 'J',
                        data: []
                    },
                    {
                        letter: 'K',
                        data: []
                    },
                    {
                        letter: 'L',
                        data: [{
                                name: `LAST PRICE`,
                                content: `Last price refers to the last traded price of the particular security. Last price represents at what price points there are willing buyers and sellers and where the most recent trade occured.`
                            },
                            {
                                name: `LAST TRADING DAY`,
                                content: `The last market day that investors can trade DW.`
                            },
                            {
                                name: `LIMIT ORDER`,
                                content: `A limit order is an order to buy or sell a security at a specific price (i.e. limit price). A buy limit order can only be executed at the limit price or lower, and a sell limit order can only be executed at the limit price or higher.`
                            },
                            {
                                name: `LIQUIDITY OF THE MARKET`,
                                content: `The state of affairs in a market in which it is generally easy to buy or sell securities without causing drastic movement in prices.`
                            },
                            {
                                name: `LIQUIDITY PROVIDER`,
                                content: `Refer to MARKET MAKER.`
                            }
                        ]
                    },
                    {
                        letter: 'M',
                        data: [{
                                name: `MATURITY DATE`,
                                content: `DW will be automatically exercised on maturity date and DW holder will receive the cash settlement amount (if the DW is in-the-money) after maturity date.`
                            },
                            {
                                name: `MARKET CAPITALISATION`,
                                content: `The total value of a listed company's outstanding shares based on current market price.`
                            },
                            {
                                name: `MARKET MAKER`,
                                content: `Market maker provides liquidity by posting bid and offer prices on the trading system on each market day over the life of the DW. Market maker can be the issuer itself (e.g. Maybank Securities (Thailand) Plc.) or an appointed designated market maker.`
                            },
                            {
                                name: `MARKET ORDER`,
                                content: `A market order is an order to buy or sell a stock at the current market price. A buy market order is an order to buy a security at the current lowest offer price; where a sell market order is an order to sell a security at the current highest bid price. The advantage of a market order is that it is almost always guaranteed that the order will be executed (as long as there are sufficient buyers and sellers). Depending on the broker's commission structure, a market order may also be less expensive than a limit order.`
                            },
                            {
                                name: `MONEYNESS`,
                                content: `Relationship between exercise price and the underlying asset price. There are three forms of moneyness, which are In-the-Money (ITM), At-the-Money (ATM) and Out-of-the-Money (OTM).`
                            }
                        ]
                    },
                    {
                        letter: 'N',
                        data: []
                    },
                    {
                        letter: 'O',
                        data: [{
                                name: `OFFER VOLUME`,
                                content: `Offer volume is the quantity of a security that sellers are willing to sell at a specified offer price. Offer volume is stated as board lots with each representing 100 units (shares) each.`
                            },
                            {
                                name: `OFFER PRICE`,
                                content: `Offer price is the lowest price a seller is willing to sell a particular security.`
                            },
                            {
                                name: `ODD LOT`,
                                content: `Unit of security that is smaller than a board lot (standard trading unit defined by the exchange).`
                            }, {
                                name: `OPEN PRICE`,
                                content: `Open price is the price at which a security first trade upon the opening of the trading session on a market day.`
                            },
                            {
                                name: `ORDER BOOK`,
                                content: `Order Book is an "electronic book" that shows the demand of the security at various prices at real time basis.`
                            }, {

                                name: `OUT-OF-THE-MONEY (OTM)`,
                                content: `An out-of-the-money DW has intrinsic value equals to zero.<br/><br/>For call DW, it is said to be out-of-the-money if Underlying Asset Price < Exercise Price;<br/>For put DW, it is said to be out-of-the-money if Exercise Price < Underlying Asset Price.`
                            }, {
                                name: `OUTSTANDING DW QUANTITY`,
                                content: `Outstanding DW quantity refers to the quantity of DW held by investors. The higher the outstanding DW quantity, the higher the probability that the DW price will be affected by the forces of market supply and demand. As a result, the DW price may not track the price of the underlying asset very closely.`
                            }
                        ]
                    },
                    {
                        letter: 'P',
                        data: [{
                            name: `PRICE MATRIX`,
                            content: `Price matrix provides the investors indicative bid prices for a DW based on various price points of the underlying asset.<br/>The actual DW prices quoted in the market may differ from the prices displayed on the website.`
                        }, {
                            name: `PREMIUM`,
                            content: `Measures the percentage of the underlying asset has to rise (for call DW) or fall (for put DW) for a DW holder to become break-even if one holds the DW until maturity.`
                        }, {
                            name: `PROXY`,
                            content: `One who is given written authority to vote for and on behalf of a shareholder at a meeting of the company.`
                        }, {
                            name: `PUT DW`,
                            content: `A put DW offers its holder the right, but not obligation to sell a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.`
                        }]
                    },
                    {
                        letter: 'Q',
                        data: [{
                            name: `QUOTATION`,
                            content: `The bid and offer prices quoted by buyers and sellers for securities listed on the exchange.`
                        }]
                    },
                    {
                        letter: 'R',
                        data: [{

                            name: `RIGHTS ISSUE`,
                            content: `Companies raise additional capital by offering to existing shareholder the rights to subscribe for new shares, at a price usually below the current market price. These Rights Issues have a market of their own and can be traded at the exchange.`
                        }]
                    },
                    {
                        letter: 'S',
                        data: [{
                            name: `STOCK SPLIT`,
                            content: `When a company reduces the market price of its shares by increasing the number of outstanding shares in the market, e.g. 100,000 units of THB 2.00 ordinary shares would be split into 200,000 units of THB 1.00 ordinary shares.`
                        }, {
                            name: `SHARIAH-COMPLIANT SECURITIES`,
                            content: `Shariah-compliant securities are securities of a public-listed company which have been classified as Shariah permissible for investment.`
                        }, {
                            name: `SHORT SELLING`,
                            content: `The action of a person selling shares which he does not own at the time of selling.`
                        }, {
                            name: `STOP ORDER`,
                            content: `A stop order is an order to buy or sell a security once the price of the security reaches a specified price (i.e. stop price). A stop order becomes a market order to buy or sell securities once the stop price is attained or penetrated.`
                        }]
                    },
                    {
                        letter: 'T',
                        data: [{
                                name: `THETA`,
                                content: `Measures the expected daily decline in the value of a DW due to time decay, all other factors remain constant. Theta is always negative as time decay is always working against a DW holder.`
                            },
                            {
                                name: `TIME DECAY`,
                                content: `Time decay is the effects of time value declining as DW is approaching its maturity.`
                            },
                            {
                                name: `TIME VALUE`,
                                content: `The difference between the DW price and intrinsic value.`
                            },
                            {
                                name: `TRADED VOLUME`,
                                content: `Traded Volume refers to the total quantity of securities transacted between buyers and sellers during the regular trading hours on the market day.`
                            }
                        ]
                    },
                    {
                        letter: 'U',
                        data: [{
                            name: `UNDERLYING ASSET`,
                            content: `The financial instrument referenced by the DW.<br/><br/>For example,<br/>The underlying asset of OR42C2206G is the OR;<br/>The underlying asset of SET5042C2206G is the SET50 Index.`
                        }]
                    },
                    {
                        letter: 'V',
                        data: [{
                                name: `VOLATILITY`,
                                content: `The uncertainty in the price movement which will affect the return of the security.<br/>Refer to HISTORICAL VOLATILITY and IMPLIED VOLATILITY`
                            },
                            {
                                name: `VOLUME WEIGHTED AVERAGE PRICE (VWAP)`,
                                content: `The VWAP for a security is a price, calculated by summing up the aggregate transaction value of the security ("traded price" multiply by "number of securities traded") and dividing it with the total number of securities traded. A VWAP can be computed for any given trading period.`
                            }
                        ]
                    },
                    {
                        letter: 'W',
                        data: [

                        ]
                    },
                    {
                        letter: 'X',
                        data: []
                    },
                    {
                        letter: 'Y',
                        data: []
                    },
                    {
                        letter: 'Z',
                        data: []
                    }
                ],
                [{
                        letter: 'A',
                        data: [{
                                name: `ค่า ALPHA `,
                                content: `ค่า Alpha ใช้วัดผลตอบแทนการลงทุนโดยเปรียบเทียบกับดัชนีอ้างอิง ซึ่งการที่ค่า Alpha เป็น 1 แสดงถึงผลตอบแทนของการลงทุนในกรอบเวลาหนึ่งที่มากกว่าค่าเฉลี่ยตลาด 1% ขณะที่ค่า Alpha ที่เป็นลบ แสดงถึงผลตอบแทนของการลงทุนที่ต่ำกว่าค่าเฉลี่ยตลาด`
                            },
                            {
                                name: `สิทธิแบบอเมริกัน (AMERICAN-STYLE)`,
                                content: `ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่สามารถใช้สิทธิได้ในทุกวันทำการตลาดไปจนถึงวันครบกำหนดอายุ`
                            },
                            {
                                name: `AT-THE-MONEY (ATM)`,
                                content: `เหตุการณ์ที่ราคาใช้สิทธิของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) เข้าใกล้หรือเท่ากับราคาของสินทรัพย์อ้างอิง โดยที่ใบสำคัญแสดงสิทธิอนุพันธ์ที่มีสถานะ at-the-money (ATM) จะไม่มีมูลค่าที่แท้จริง แต่อาจยังคงมีมูลค่าเวลา (ขึ้นอยู่กับอายุคงเหลือ) ตัวอย่างเช่น หุ้น XYZ ที่มีการซื้อขายที่ ราคา 2.00 บาท ทำให้ Call DW หรือ Put DW ที่มีราคาใช้สิทธิ 2.00 บาท มีสถานะเป็น at-the-money (ATM)`
                            }
                        ]
                    },
                    {
                        letter: 'B',
                        data: [{
                                name: `ตลาดหมี`,
                                content: `ภาวะตลาดหุ้นที่มีผู้ขายมากว่าผู้ซื้อ เป็นผลทำให้เกิดแนวโน้มการลดลงของราคาหลักทรัพย์`
                            },
                            {
                                name: `ปริมาณเสนอซื้อ`,
                                content: `ปริมาณเสนอซื้อ คือปริมาณหลักทรัพย์ที่ผู้ซื้อเต็มใจที่จะซื้อ ณ ราคาเสนอซื้อที่ระบุ ซึ่งปริมาณเสนอซื้อจะระบุเป็นหน่วยการซื้อขาย โดย 1 หน่วยการซื้อขาย คิดเป็น 100 หน่วย (หุ้น)`
                            },
                            {
                                name: 'ราคาเสนอซื้อ',
                                content: 'ราคาเสนอซื้อ คือราคาสูงที่สุดที่ผู้ซื้อเต็มใจที่จะซื้อหลักทรัพย์นั้น ๆ'
                            },
                            {
                                name: 'แบบจำลองแบล็ค-โชลส์',
                                content: 'หนึ่งในแบบจำลองที่นิยมใช้ในการคำนวณราคาออปชัน'
                            },
                            {
                                name: 'หน่วยการซื้อขาย',
                                content: 'หน่วยการซื้อขายที่เป็นมาตรฐานกำหนดโดยตลาดหลักทรัพย์ สำหรับตลาดหลักทรัพย์แห่งประเทศไทย (SET) 1 หน่วยการซื้อขายมีค่าเท่ากับหลักทรัพย์ 100 หน่วย ซึ่งในกรณีที่หลักทรัพย์มีราคาปิดตั้งแต่ 500 บาทขึ้นไปเป็นระยะเวลา 6 เดือนติดต่อกัน 1 หน่วยการซื้อขายจะเท่ากับ 50 หลักทรัพย์'
                            },
                            {
                                name: 'การให้หุ้นปันผลแก่ผู้ถือหุ้นเดิม',
                                content: 'การให้หุ้นปันผลแก่ผู้ถือหุ้นเดิม คือการที่บริษัทให้หุ้นกับผู้ถือหุ้นโดยไม่มีค่าธรรมเนียม แทนที่จะจ่ายเงินปันผล บริษัทเสนอที่จะให้หุ้นเพิ่มเติมแก่ผู้ถือหุ้น ตัวอย่างเช่น บริษัทอาจตัดสินใจให้หุ้นปันผลแก่ผู้ถือหุ้นในอัตรา 1 หุ้นปันผล ต่อการถือหุ้นนั้น 10 หุ้น'
                            },
                            {
                                name: 'นายหน้าซื้อขายหลักทรัพย์',
                                content: 'บริษัทหลักทรัพย์ที่ได้รับใบอนุญาตในการประกอบธุรกิจหลักทรัพย์ประเภทการเป็นนายหน้าซื้อขายหลักทรัพย์ ทำหน้าที่เป็นตัวแทนในการซื้อหรือขายหลักทรัพย์ให้แก่นักลงทุน '
                            },
                            {
                                name: 'ตลาดกระทิง',
                                content: 'ภาวะตลาดหุ้นที่มีผู้ซื้อมากว่าผู้ขาย เป็นผลทำให้เกิดแนวโน้มการเพิ่มขึ้นของราคาหลักทรัพย์'
                            },
                            {
                                name: 'จุดคุ้มทุน',
                                content: 'การที่ระดับราคาในการซื้อขายไม่ได้มีกำไรหรือขาดทุน'
                            }

                        ]
                    },
                    {
                        letter: 'C',
                        data: [{
                                name: 'CALL DW',
                                content: 'Call DW ให้สิทธิผู้ถือ แต่ไม่ได้เป็นพันธะที่จะต้องซื้อสินทรัพย์อ้างอิงในจำนวน และราคาที่กำหนดไว้ภายในระยะเวลาที่กำหนด'
                            },
                            {
                                name: 'CALLABLE BULL/BEAR CONTRACTS (CBBC)',
                                content: `CBBC เป็นการลงทุนล้อไปกับผลตอบแทนของหุ้นอ้างอิง โดยที่นักลงทุนไม่ต้องจ่ายเงินเต็มจำนวนเหมือนการลงทุนในหุ้นจริง โดยมีทั้งแบบ Bull หรือ Bear ที่มีการกำหนดอายุไว้ โดยให้นักลงทุนเลือกสถานะ bullish หรือ bearish ฝั่งใดก็ตาม สำหรับหุ้นอ้างอิงนั้น ๆ โดยมีโอกาสที่จะเกิดการไถ่ถอนก่อนกำหนดได้ก่อนครบกำหนดอายุ เมื่อหุ้นอ้างอิงปรับตัวสวนทางกับที่นักลงทุนคาดการณ์`
                            },
                            {
                                name: `จำนวนการชำระราคาเป็นเงินสด`,
                                content: `จำนวนการชำระราคาเป็นเงินสดมีค่าเป็นบวก โดยจะได้รับจากการใช้สิทธิใบสำคัญแสดงสิทธิอนุพันธ์ (DW) เมื่อครบกำหนดอายุ<br/>ในกรณีที่จำนวนการชำระราคาเป็นเงินสดน้อยกว่าหรือเท่ากับ 0 ผู้ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จะไม่ได้รับการจ่ายเงินใด ๆ จากใบสำคัญแสดงสิทธิอนุพันธ์ (DW)`
                            }
                        ]
                    },
                    {
                        letter: 'D',
                        data: [{
                                name: `ค่า DELTA `,
                                content: `ค่า Delta เป็นอัตราส่วนการเปลี่ยนแปลงระดับราคาของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) เทียบกับการเปลี่ยนแปลงของระดับราคาของสินทรัพย์อ้างอิง โดยค่า Delta จะแสดงเป็นค่าสัมบูรณ์ของการเปลี่ยนแปลงไปของราคาใบสำคัญแสดงสิทธิอนุพันธ์ (DW) หากราคาของสินทรัพย์อ้างอิงเปลี่ยนแปลง<br/><br/>โดยปกติ ค่า Delta จะอยู่ในช่วงระหว่าง 0 ถึง 1 สำหรับ call DW และ 0 ถึง -1 สำหรับ put DW ดังตัวอย่าง (สมมติให้ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) มีอัตราการใช้สิทธิเท่ากับ 1) ค่า delta ที่ 0.50 จะสื่อว่าถ้ามูลค่าของสินทรัพย์อ้างอิงเปลี่ยนแปลง 0.20 บาท มูลค่าของ call DW จะเปลี่ยนแปลง 0.10 บาท`
                            },
                            {
                                name: `การคิดลด (DISCOUNT)`,
                                content: `จำนวนที่ราคาของสินทรัพย์ต่ำกว่ามูลค่าที่ตราไว้ `
                            },
                            {
                                name: `อัตราผลตอบแทนจากเงินปันผล`,
                                content: `จำนวนเงินปันผลต่อปีของบริษัทที่แสดงเป็นร้อยละของราคาปัจจุบันของหุ้นบริษัทนั้น ๆ `
                            },
                            {
                                name: `ราคาสูงสุด`,
                                content: `ราคาสูงสุดเป็นราคาระหว่างวันของหลักทรัพย์นั้น ๆ ที่เป็นราคาซื้อขายที่สูงที่สุดของวันทำการซื้อขายนั้น ๆ ในระหว่างชั่วโมงการซื้อขายปกติ `
                            },
                            {
                                name: `ผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ (DW)`,
                                content: `หน่วยงานที่ออกใบสำคัญแสดงสิทธิอนุพันธ์ (DW) โดยปกติจะเป็นสถาบันทางการเงิน เช่น บริษัทหลักทรัพย์ เมย์แบงก์ (ประเทศไทย) จำกัด (มหาชน) เป็นต้น`
                            },
                            {
                                name: `สภาพคล่องของใบสำคัญแสดงสิทธิอนุพันธ์ (DW Liquidity)`,
                                content: `การที่ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) มีสภาพคล่อง จะมีลักษณะ ดังนี้<br/><br/>1) มีปริมาณการซื้อขายที่เหมาะสม และหรือ<br/>2) มีช่วงราคาเสนอซื้อและเสนอขายที่เพียงพอ`
                            },
                            {
                                name: `ใบสำคัญแสดงสิทธิอนุพันธ์`,
                                content: `ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ให้สิทธิกับผู้ถือแต่ไม่ได้เป็นพันธะในการซื้อหรือขายสินทรัพย์อ้างอิงนั้น ๆ ที่จำนวน ราคาและระยะเวลาที่กำหนดไว้ล่วงหน้า`
                            },
                            {
                                name: `เครื่องคำนวณใบสำคัญแสดงสิทธิอนุพันธ์ (DW CALCULATOR)`,
                                content: `เครื่องคำนวณใบสำคัญแสดงสิทธิอนุพันธ์ทำให้นักลงทุนประมาณราคาทางทฤษฎีของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) นั้น ๆ จากตัวแปร เช่น ราคาสินทรัพย์อ้างอิง อายุคงเหลือ และหรือ ความผันผวนแฝง<br/><br/>นักลงทุนสามารถประมาณราคาของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จากการเคลื่อนไหวของราคาสินทรัพย์อ้างอิง และ/หรือ ความผันผวนแฝง ขณะที่ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW)`
                            },
                            {
                                name: `ชั่วโมงการซื้อขายใบสำคัญแสดงสิทธิอนุพันธ์ (DW TRADING HOURS)`,
                                content: `ชั่วโมงการซื้อขายใบสำคัญแสดงสิทธิอนุพันธ์ สำหรับดัชนี SET และหุ้นไทย ช่วงเช้า เปิดระหว่าง 09.55-10.00 ถึง 12.30 ช่วงบ่าย เปิดระหว่าง 14.25-14.30 ถึง 16.35-16.40`
                            },
                            {
                                name: `ผู้ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW HOLDER)`,
                                content: `บุคคลที่ปรากฎชื่อเป็นผู้ฝากใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ในบริษัท ศูนย์รับฝากหลักทรัพย์ (ประเทศไทย) จำกัด (TSD)`
                            }, {
                                name: `หนังสือชี้ชวนของใบสำคัญแสดงสิทธิอนุพันธ์ (DW PROSPECTUS)`,
                                content: `เอกสารที่ออกโดยผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ (DW issuer) ในการเสนอขายใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่จะมีการออกในช่วงเวลานั้น `
                            }
                        ]
                    },
                    {
                        letter: 'E',
                        data: [{
                                name: `กำไรสุทธิต่อหุ้น`,
                                content: `ส่วนของกำไรสุทธิจากผลการดำเนินงานของบริษัทต่อปีที่แบ่งให้แก่หุ้นสามัญของบริษัทนั้นในแต่ละหุ้น`
                            },
                            {
                                name: `อัตราทด (EFFECTIVE GEARING)`,
                                content: `ใช้คำนวณราคาของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) สำหรับทุกการเปลี่ยนแปลงของราคาสินทรัพย์อ้างอิงที่ 1% ตัวอย่างเช่น อัตราทด 10 เท่า โดยปัจจัยอื่นไม่เปลี่ยนแปลง หมายถึง ทุกการเปลี่ยนแปลงของราคาสินทรัพย์อ้างอิงที่ 1% ราคาของใบสำคัญแสดงสิทธิอนุพันธ์จะเปลี่ยนแปลง 10%`
                            },
                            {
                                name: `เวลาทำการซื้อขาย`,
                                content: `เวลาทำการซื้อขายหุ้น แบ่งเป็น 2 ช่วง ทั้งแบบปกติ และการซื้อขายหน่วยย่อย<br/>มาเลเซีย: ช่วงแรก เวลา 8.30 น.-12.30 น. และช่วงที่สอง เวลา 14.00 น. - 17.00 น.<br/>ฮ่องกง: ช่วงแรก เวลา 9.00 น.-12.00 น. และช่วงที่สอง เวลา 13.00 น.-16.00 น.<br/> ไทย: ช่วงแรก เวลาเปิดทำการที่ระบบการซื้อขายจะสุ่มเลือก เวลา 9.55 น. ถึง 10.00 น.-12.30 น. และช่วงที่สอง เวลาเปิดทำการที่ระบบการซื้อขายจะสุ่มเลือก เวลา 14.25 น. ถึง 14.30 น. และ เวลาปิดทำการที่ระบบการซื้อขายจะสุ่มเลือก เวลา 16.35 น ถึง 16.40 น.`
                            },
                            {
                                name: `สิทธิแบบยุโรป (EUROPEAN-STYLE)`,
                                content: `ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จะใช้สิทธิอัตโนมัติในวันครบกำหนดอายุที่มีการกำหนดไว้ล่วงหน้า`
                            },
                            {
                                name: `ราคาหรือระดับราคาใช้สิทธิ`,
                                content: `ราคาหรือระดับราคาใช้สิทธิ จะถูกกำหนดไว้ล่วงหน้าสำหรับผู้ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW) สำหรับการซื้อหรือขายสินทรัพย์อ้างอิง โดยจะนำไปใช้คำนวณจำนวนการชำระราคาเป็นเงินสดที่จะชำระราคาใบสำคัญแสดงสิทธิอนุพันธ์เมื่อครบกำหนดอายุ`
                            },
                            {
                                name: `อัตราการใช้สิทธิ`,
                                content: `จำนวนหน่วยของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่ใช้สำหรับแลกเปลี่ยนเป็นสินทรัพย์อ้างอิง 1 หน่วย<br/><br/>ตัวอย่างเช่น ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ประเภทสิทธิในการซิ้อ (call DW) อ้างอิงหุ้น XYZ คิดเป็นอัตราส่วน 5 ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ต่อ 1 หุ้น XYZ กำหนดให้ต้องมีใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ประเภทสิทธิในการซื้อ (call DW) 5 หน่วย ในการใช้สิทธิซื้อ 1 หุ้น XYZ ที่ราคาใช้สิทธิ<br/>สำหรับใบสำคัญแสดงสิทธิอนุพันธ์ที่อ้างอิงราคาหุ้น อัตราส่วนสามารถปรับเนื่องจาก Corporate Action เช่น การให้หุ้นปันผลแก่ผู้ถือหุ้นเดิม (bonus issue) การแตกหุ้น (stock split) สิทธิจองซื้อหุ้นเพิ่มทุนของผู้ถือหุ้นเดิม (rights issue) การรวมหุ้น (reverse stock split) หรือเงินปันผลที่จ่ายนอกเหนือจากเงินปันผลปกติ (special dividend) `
                            },
                            {
                                name: `กองทุนรวม ETF`,
                                content: `กองทุนรวม ETF เป็นผลิตภัณฑ์ทางการเงินรูปแบบหนึ่ง ที่มีนโยบายสร้างผลตอบแทนล้อกับดัชนีหรือราคาสินทรัพย์ที่กองทุนใช้อ้างอิง โดยความพิเศษของ ETF คือ การนำเอาจุดเด่นของหุ้นและกองทุนรวมดัชนีมารวมไว้ด้วยกัน<br/><br/>โดยทั่วไปกองทุนรวม ETF มีหลายประเภท ได้แก่ กองทุนรวม ETF ที่ลงทุนในหุ้น, กองทุนรวม ETF ที่ลงทุนล้อตามหรือสวนทางสินทรัพย์อ้างอิง, กองทุนรวม ETF ตราสารหนี้ และกองทุนรวม ETF ที่ลงทุนในกลุ่มสินค้าโภคภัณฑ์ โดยกองทุนรวม ETF เหล่านี้ ประกอบไปด้วยตะกร้าหุ้น หุ้นกู้ สัญญาซื้อขายฟิวเจอร์ส และสินค้าโภคภัณฑ์ ตามดัชนี้นั้น ๆ ที่มีการกระจายการลงทุน และลดความเสี่ยงจากการลงทุนในหุ้นจากบริษัทเดียว<br/><br/>สภาพคล่องของ ETF สะท้อนสภาพคล่องของหุ้นอ้างอิงในตะกร้าหลักทรัพย์ `
                            }
                        ]
                    },
                    {
                        letter: 'F',
                        data: [{
                            name: `สัญญาซื้อขายฟิวเจอร์ส`,
                            content: `สัญญาซื้อขายฟิวเจอร์สเป็นพันธะสัญญาทางการเงินระหว่างผู้ซื้อเพื่อซื้อหลักทรัพย์อ้างอิง หรือผู้ขายเพื่อที่จะขายหลักทรัพย์อ้างอิง ในวันที่ตกลงกันในอนาคต`
                        }, {
                            name: `วันซื้อขายวันแรก`,
                            content: `วันที่ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ได้ถูกจดทะเบียนและเริ่มซื้อขายในตลาดหลักทรัพย์ ซึ่งนักลงทุนสามารถเริ่มซื้อขาย DW ได้วันแรก`
                        }]
                    },
                    {
                        letter: 'G',
                        data: [{
                                name: `ค่า GAMMA`,
                                content: `วัดอัตราการเปลี่ยนแปลงของค่าเดลต้า (Delta) ของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ต่อการเปลี่ยนแปลงราคาสินทรัพย์อ้างอิง โดยที่ตัวแปรอื่นคงที่`
                            },
                            {
                                name: `อัตราทด (GEARING)`,
                                content: `เป็นสัดส่วนของราคาสินทรัพย์อ้างอิงเทียบกับราคาของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) โดยแสดงอัตราทดของใบสำคัญแสดงสิทธิอนุพันธ์ (DW)<br/><br/>ตัวอย่าง การซื้อใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่มีอัตราทด 10 เท่า หมายถึงราคาใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จะมีการเปลี่ยนแปลง 10 เท่า มากกว่าการซื้อสินทรัพย์อ้างอิง 1 หน่วย โดยประมาณการเพิ่มขึ้นหรือลดลงของราคาใบสำคัญแสดงสิทธิอนุพันธ์ (DW) เทียบกับราคาของสินทรัพย์อ้างอิง จะต้องดูอัตราทด (Effective Gearing)  `
                            },
                            {
                                name: `หุ้นเติบโต`,
                                content: `หุ้นของบริษัทที่มีมุมมองเชิงบวกจากการขยายตัวของกำไร และมูลค่าบริษัท โดยคาดว่าจะมีโอกาสในการสร้างส่วนต่างกำไรให้กับผู้ถือหุ้นในอนาคต จากราคาหุ้นที่เพิ่มขึ้น เงินปันผล การให้หุ้นปันผลแก่ผู้ถือหุ้นเดิม และสิทธิจองซื้อหุ้นเพิ่มทุนของผู้ถือหุ้นเดิม`
                            },
                        ]
                    },
                    {
                        letter: 'H',
                        data: [{
                                name: `HEDGE`,
                                content: `การ Hedge หรือ Hedging เป็นหนึ่งในกลยุทธ์ในการลดความเสี่ยงจากการที่ราคาของสินทรัพย์สวนทางจากที่คาดการณ์ โดยปกติ การ Hedge จะมาจากการหักล้างสถานะของหลักทรัพย์ เพื่อปกป้องมูลค่าของพอร์ตการลงทุน `
                            },
                            {
                                name: `ความผันผวนในอดีต`,
                                content: `ใช้อ้างอิงความผันผวนของราคาสินทรัพย์อ้างอิงในช่วงที่ผ่านมา โดยปกติจะแสดงเป็นช่วงจำนวนวัน เช่น ค่าความผันผวนในช่วง 10 วัน หรือ 90 วัน เป็นต้น `
                            }

                        ]
                    },
                    {
                        letter: 'I',
                        data: [{
                                name: `ความผันผวนแฝง`,
                                content: `เป็นปัจจัยหนึ่งที่สำคัญที่ส่งผลต่อราคาของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) โดยแสดงค่าคาดการณ์ความผันผวนของราคาสินทรัพย์อ้างอิงนั้น ๆ ซึ่งใช้เป็นตัวชี้วัดว่าใบสำคัญแสดงสิทธินั้น ๆ ถูกหรือแพง เมื่อกำหนดให้ตัวแปรอื่นมีค่าเท่ากัน ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่มีความผันผวนแฝงสูงกว่า แสดงว่าใบสำคัญแสดงสิทธิอนุพันธ์ (DW) นั้นแพงกว่า`
                            },
                            {
                                name: `ดัชนี`,
                                content: `เป็นพอร์ตการลงทุนสมมติของสินทรัพย์ ตลาดหลักทรัพย์ หรือกลุ่มธุรกิจของตลาด ที่ค่าดัชนีเป็นมูลค่าตัวเลขคำนวณจากราคาของส่วนประกอบในพอร์ต ซึ่งมูลค่าดัชนีมักใช้สำหรับการวัดความสามารถในการสร้างผลตอบแทนของพอร์ตการลงทุน `
                            },
                            {
                                name: `IN THE MONEY (ITM)`,
                                content: `ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่มีสถานะ ITM จะมีมูลค่าที่แท้จริงมากกว่าศูนย์ <br/><br/>สำหรับ call DW จะมีสถานะเป็น ITM หากราคาสินทรัพย์อ้างอิง > ราคาใช้สิทธิ<br/>สำหรับ put DW จะมีสถานะเป็น ITM หากราคาใช้สิทธิ > ราคาสินทรัพย์อ้างอิง`
                            },
                            {
                                name: `มูลค่าที่แท้จริง`,
                                content: `มูลค่าที่แท้จริงของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) คือส่วนต่างระหว่างราคาสินทรัพย์อ้างอิงกับราคาใช้สิทธิ ซึ่งมูลค่าที่แท้จริงจะมีค่าน้อยกว่าศูนย์ไม่ได้`
                            },
                            {
                                name: `ราคาต่ำสุด`,
                                content: `ราคาต่ำสุดที่มีการซื้อขายระหว่างวันของหลักทรัพย์ หมายถึงราคาที่ต่ำที่สุดของราคาหลักทรัพย์ที่มีการซื้อขาย ณ วันซื้อขายนั้นๆ`
                            }
                        ]
                    },
                    {
                        letter: 'J',
                        data: []
                    },
                    {
                        letter: 'K',
                        data: []
                    },
                    {
                        letter: 'L',
                        data: [{
                                name: `ราคาล่าสุด`,
                                content: `ราคาล่าสุดของหลักทรัพย์นั้น ๆ ที่มีการซื้อขาย ซึ่งจะแสดงราคาที่ผู้ซื้อและผู้ขายมีการซื้อขายล่าสุด`
                            },
                            {
                                name: `วันซื้อขายวันสุดท้าย `,
                                content: `วันทำการสุดท้ายที่นักลงทุนสามารถทำการซื้อขายใบสำคัญแสดงสิทธิอนุพันธ์ (DW) `
                            },
                            {
                                name: `คำสั่งซื้อขายที่กำหนดราคาเอง`,
                                content: `เป็นคำสั่งซื้อขายหลักทรัพย์ ณ ราคาที่กำหนด (หรือที่เรียกว่า ราคา limit) โดยการส่งคำสั่ง buy limit จะซื้อสำเร็จได้เฉพาะ ณ ราคาที่กำหนด หรือต่ำกว่านั้น และการส่งคำสั่ง sell limit จะขายสำเร็จได้เฉพาะ ณ ราคาที่กำหนด หรือสูงกว่านั้น `
                            },
                            {
                                name: `สภาพคล่องตลาด `,
                                content: `สถานะของตลาดหลักทรัพย์ ซึ่งมีความง่ายในการซื้อหรือขายหลักทรัพย์โดยไม่เป็นการทำให้เกิดการเคลื่อนไหวของราคาอย่างรุนแรง`
                            },
                            {
                                name: `ผู้ดูแลสภาพคล่อง`,
                                content: `หรือที่เรียกว่า Market Maker `
                            }
                        ]
                    },
                    {
                        letter: 'M',
                        data: [{
                                name: `วันครบกำหนดอายุ`,
                                content: `วันที่ทำการใช้สิทธิของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) โดยจะเป็นการใช้สิทธิแบบอัตโนมัติ ซึ่งผู้ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW holder) จะได้รับเงินสดส่วนต่าง (หาก DW มีสถานะ ITM) หลังจากวันครบกำหนดอายุ`
                            },
                            {
                                name: `มูลค่าหลักทรัพย์ตามราคาตลาด`,
                                content: `มูลค่าทั้งหมดของบริษัทจดทะเบียนตามราคาปัจจุบันของหุ้นที่อยู่ในตลาด `
                            },
                            {
                                name: `ผู้ดูแลสภาพคล่อง`,
                                content: `ผู้ดูแลสภาพคล่องจะทำให้เกิดสภาพคล่องโดยส่งราคาเสนอซื้อและราคาเสนอขายในระบบการซื้อขายในแต่ละวันทำการตลาดตลอดอายุของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) โดยผู้ดูแลสภาพคล่องสามารถเป็นผู้ออกได้ (เช่น บมจ.หลักทรัพย์ เมย์แบงก์ (ประเทศไทย) จำกัด (มหาชน)) หรือผู้ดูแลสภาพคล่องที่ได้รับมอบหมาย`
                            },
                            {
                                name: `คำสั่งซื้อขาย ณ ราคาตลาด`,
                                content: `คำสั่งซื้อขาย ณ ราคาตลาด เป็นการส่งคำสั่งซื้อหรือคำสั่งขายหุ้นที่ราคาปัจจุบันของหุ้นนั้น ๆ ซึ่งการส่งคำสั่งซื้อ ณ ราคาตลาด เป็นการส่งคำสั่งซื้อหลักทรัพย์ที่ราคาเสนอขายที่ต่ำที่สุดในขณะนั้น ขณะที่การส่งคำสั่งขาย ณ ราคาตลาด เป็นการส่งคำสั่งขายหลักทรัพย์ที่ราคาเสนอซื้อที่สูงที่สุดในขณะนั้น โดยข้อดีของการส่งคำสั่งซื้อขาย ณ ราคาตลาด คือมีโอกาสที่จะส่งคำสั่งซื้อหรือขายสำเร็จ (ตราบเท่าที่มีผู้ซื้อหรือผู้ขายเพียงพอ)  โดยที่คำสั่งซื้อขาย ณ ราคาตลาดอาจมีค่าธรรมเนียมถูกกว่าคำสั่งซื้อขายที่กำหนดราคาเอง ขึ้นอยู่กับโครงสร้างค่าธรรมเนียมซื้อขายของแต่ละนายหน้าซื้อขายหลักทรัพย์`
                            },
                            {
                                name: `MONEYNESS`,
                                content: `ความสัมพันธ์ระหว่างราคาใช้สิทธิ กับราคาของสินทรัพย์อ้างอิง โดย Moneyness จะมี 3 สถานะ ได้แก่ In-the-Money (ITM), At-the-Money (ATM) และ Out-of-the-Money (OTM)`
                            }
                        ]
                    },
                    {
                        letter: 'N',
                        data: []
                    },
                    {
                        letter: 'O',
                        data: [{
                                name: `ปริมาณเสนอขาย`,
                                content: `ปริมาณเสนอขาย คือปริมาณหลักทรัพย์ที่ผู้ขายเต็มใจที่จะขาย ณ ราคาเสนอขายที่ระบุ ซึ่งปริมาณเสนอขายจะระบุเป็นหน่วยการซื้อขาย โดย 1 หน่วยการซื้อขายคิดเป็น 100 หน่วย (หุ้น) `
                            },
                            {
                                name: `ราคาเสนอขาย`,
                                content: `ราคาเสนอขาย คือราคาต่ำที่สุดที่ผู้ขายเต็มใจที่จะขายหลักทรัพย์นั้น ๆ`
                            },
                            {
                                name: `หน่วยย่อย`,
                                content: `จำนวนหุ้นที่น้อยกว่าหนึ่งหน่วยการซื้อขาย (หน่วยการซื้อขายมาตรฐานจะกำหนดโดยตลาดหลักทรัพย์)`
                            }, {
                                name: `ราคาเปิด`,
                                content: `ราคาเปิดเป็นราคาแรกของหลักทรัพย์ที่มีการซื้อขายในวันทำการ ณ ช่วงเวลาตลาดเปิดของวันทำการตลาด`
                            },
                            {
                                name: `บันทึกประวัติรายการซื้อขาย `,
                                content: `บันทึกประวัติรายการซื้อขายจะเป็นรูปแบบอิเล็กทรอนิกส์ที่แสดงความต้องการของหลักทรัพย์ที่ระดับราคาต่าง ๆ ณ เวลาปัจจุบัน `
                            }, {

                                name: `OUT-OF-THE-MONEY (OTM) `,
                                content: `ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่มีสถานะ OTM จะมีมูลค่าที่แท้จริงเท่ากับศูนย์ <br/><br/>สำหรับ call DW จะมีสถานะเป็น OTM หากราคาสินทรัพย์อ้างอิง < ราคาใช้สิทธิ<br/>สำหรับ put DW จะมีสถานะเป็น OTM หากราคาใช้สิทธิ < ราคาสินทรัพย์อ้างอิง`
                            }, {
                                name: `จำนวนใบสำคัญแสดงสิทธิอนุพันธ์คงค้าง (OUTSTANDING DW QUANTITY)`,
                                content: `จำนวนใบสำคัญแสดงสิทธิอนุพันธ์คงค้าง คือจำนวนใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่ถือโดยนักลงทุน ยิ่งจำนวนใบสำคัญแสดงสิทธิคงค้างเยอะ หมายถึงโอกาสที่ราคาใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จะถูกควบคุมโดยอุปสงค์และอุปทานของตลาดจะสูงขึ้น ส่งผลให้ราคาใบสำคัญแสดงสิทธิอนุพันธ์ (DW) มีโอกาสจะไม่แปรผันตรงตามสินทรัพย์อ้างอิงอย่างใกล้ชิด `
                            }
                        ]
                    },
                    {
                        letter: 'P',
                        data: [{
                            name: `PRICE MATRIX`,
                            content: `Price Matrix แสดงราคาเสนอซื้อโดยประมาณของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ณ ระดับราคาสินทรัพย์อ้างอิงต่าง ๆ ซึ่งราคาจริงของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ในตลาดอาจจะแตกต่างจากราคาที่แสดงในหน้าเว็บไซต์ได้`
                        }, {
                            name: `ค่าพรีเมี่ยม`,
                            content: `ใช้วัดเปอร์เซ็นต์ของสินทรัพย์อ้างอิงที่จะเพิ่มขึ้น (call DW) หรือลดลง (put DW) สำหรับผู้ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ในการเข้าสู่จุดคุ้มทุน หากนักลงทุนถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จนครบกำหนดอายุ`
                        }, {
                            name: `ผู้รับมอบฉันทะ`,
                            content: `บุคคลซึ่งผู้ถือหุ้นลงลายมือชื่อมอบฉันทะให้ใช้สิทธิในการออกเสียงในที่ประชุมผู้ถือหุ้นแทนตน`
                        }, {
                            name: `PUT DW`,
                            content: `Put DW ให้สิทธิผู้ถือ แต่ไม่ได้เป็นพันธะที่จะต้องขายสินทรัพย์อ้างอิงในจำนวน และราคาที่กำหนดไว้ภายในระยะเวลาที่กำหนด`
                        }]
                    },
                    {
                        letter: 'Q',
                        data: [{
                            name: `ราคาเสนอซื้อและเสนอขาย`,
                            content: `ราคาเสนอซื้อและเสนอขายของผู้ซื้อและผู้ขายสำหรับหลักทรัพย์ที่จดทะเบียนอยู่ในตลาดหลักทรัพย์`
                        }]
                    },
                    {
                        letter: 'R',
                        data: [{
                            name: `สิทธิจองซื้อหุ้นออกใหม่`,
                            content: `บริษัทต้องการเพิ่มทุนโดยการให้ผู้ถือหุ้นเดิมมีสิทธิที่จะจองซื้อหุ้นใหม่ ในราคาที่โดยปกติจะต่ำกว่าราคาตลาดในปัจจุบัน ซึ่งสิทธิจองซื้อหุ้นออกใหม่มีตลาดซื้อขายและสามารถซื้อขายในตลาดหลักทรัพย์`
                        }]
                    },
                    {
                        letter: 'S',
                        data: [{
                            name: `การแตกหุ้น`,
                            content: `เมื่อบริษัทต้องการลดราคาหุ้นในตลาด โดยการเพิ่มจำนวนหุ้นในตลาด เช่น แตกหุ้นสามัญจาก 100,000 หน่วย ที่ราคา 2.00 บาท เป็น 200,000 หน่วย ที่ราคา 1.00 บาท`
                        }, {
                            name: `SHARIAH-COMPLIANT SECURITIES`,
                            content: `Shariah-compliant securities เป็นหลักทรัพย์ของบริษัทจดทะเบียนที่จัดอยู่ในการลงทุนที่สอดคล้องตามหลักศาสนาอิสลาม`
                        }, {
                            name: `การขายชอร์ต`,
                            content: `การที่บุคคลหนึ่งขายหุ้นที่บุคคลนั้นไม่ได้เป็นเจ้าของหรือถือหุ้นนั้นอยู่ในช่วงเวลาที่ทำการขายดังกล่าว `
                        }, {
                            name: `STOP ORDER`,
                            content: `Stop order เป็นคำสั่งซื้อหรือขายหลักทรัพย์หากราคาหลักทรัพย์ไปถึงราคาที่กำหนดไว้ (หรือที่เรียกว่า stop price) จะกลายเป็นคำสั่งซื้อขายหลักทรัพย์ ณ ราคาตลาดเมื่อราคาของหลักทรัพย์ไปถึง stop price ที่กำหนดไว้`
                        }]
                    },
                    {
                        letter: 'T',
                        data: [{
                                name: `ค่า THETA`,
                                content: `ใช้วัดค่าประมาณการลดลงของมูลค่าใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ในแต่ละวันเนื่องจากการมีค่าเสื่อมเวลา โดยที่ปัจจัยอื่นคงที่ ค่า Theta จะมีค่าเป็นลบ เนื่องจากค่าเสื่อมเวลามักจะตรงข้ามกับฝั่งผู้ถือใบสำคัญแสดงสิทธิอนุพันธ์ (DW holder) `
                            },
                            {
                                name: `ค่าเสื่อมเวลา`,
                                content: `ค่าเสื่อมเวลาจะส่งผลให้มูลค่าเวลาลดลง เนื่องจากระยะเวลาคงเหลือของใบสำคัญแสดงสิทธิอนุพันธ์ (DW) จะลดลงเมื่อใกล้ครบกำหนดอายุ`
                            },
                            {
                                name: `มูลค่าเวลา`,
                                content: `ส่วนต่างระหว่างราคาใบสำคัญแสดงสิทธิอนุพันธ์ (DW) กับมูลค่าที่แท้จริง`
                            },
                            {
                                name: `ปริมาณการซื้อขาย`,
                                content: `ปริมาณการซื้อขาย ในที่นี้หมายถึงปริมาณหลักทรัพย์ทั้งหมดที่มีการซื้อขายระหว่างผู้ซื้อและผู้ขายในวันทำการ ในช่วงชั่วโมงการซื้อขาย`
                            }
                        ]
                    },
                    {
                        letter: 'U',
                        data: [{
                            name: `สินทรัพย์อ้างอิง`,
                            content: `ตราสารทางการเงินที่อ้างอิงกับใบสำคัญแสดงสิทธิอนุพันธ์ (DW)<br/><br/>ตัวอย่างเช่น <br/>สินทรัพย์อ้างอิงของ OR42C2206G คือหุ้น OR<br/>สินทรัพย์อ้างอิงของ SET5042C2206G คือดัชนี SET50`
                        }]
                    },
                    {
                        letter: 'V',
                        data: [{
                                name: `ความผันผวน`,
                                content: `ความไม่แน่นอนจากการเคลื่อนไหวของราคาที่สามารถส่งผลต่อผลตอบแทนของหลักทรัพย์ 
                                ในที่นี้ หมายถึง ความผันผวนในอดีต และความผันผวนแฝง`
                            },
                            {
                                name: `ราคาเฉลี่ยถ่วงน้ำหนักตามปริมาณ (VWAP)`,
                                content: `ราคาเฉลี่ยของหุ้น คำนวณจากผลรวมปริมาณซื้อขายของทุกการซื้อขายของหุ้นนั้น ๆ (ราคาซื้อขาย คูณด้วย จำนวนหลักทรัพย์ที่ซื้อขาย) หารด้วยจำนวนหลักทรัพย์ที่มีการซื้อขายทั้งหมด โดย VWAP สามารถคำนวณในช่วงเวลาที่ต้องการได้ `
                            }
                        ]
                    },
                    {
                        letter: 'W',
                        data: [

                        ]
                    },
                    {
                        letter: 'X',
                        data: []
                    },
                    {
                        letter: 'Y',
                        data: []
                    },
                    {
                        letter: 'Z',
                        data: []
                    }
                ]
            ],
            GlossarySeek: [],
            dataindex: 0,
            letter: '',
            indexN: 0
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 改变语言
        ChangeLanguage: function(index) {
            $('#Glossary').find('.btnBox').find('button').eq(index).addClass('mark')
            $('#Glossary').find('.btnBox').find('button').eq(index).siblings('button').removeClass('mark')
            this.dataindex = index
            this.relevantData(this.letter, this.indexN)
            this.GitGlossarySeek(this.GlossaryData[index])
        },
        // 点击显示相应数据
        relevantData: function(letter, index) {
            this.indexN = index
            this.letter = letter
            $('#Glossary').find('.content').find('li').find('p').slideUp();
            $('#Glossary').find('.content').find('li').find('i').addClass('bi-caret-down-fill')
            $('#Glossary').find('.content').find('li').find('i').removeClass('bi-caret-up-fill')
            $('#Glossary').find('.letterBox').find('th').eq(index).addClass('markth')
            $('#Glossary').find('.letterBox').find('th').eq(index).siblings('th').removeClass('markth')
            this.GlossaryShwoData = this.GlossaryData[this.dataindex].filter(item => item.letter == letter)
            this.GlossaryShwoData = this.GlossaryShwoData[0].data
        },
        // 获得 搜索框数据
        GitGlossarySeek: function(data) {
            this.GlossarySeek = []
            $.each(data, (i, c) => {
                $.each(c.data, (di, dc) => {
                    this.GlossarySeek.push([dc.name, i, di])
                })
            })
        },
        // 详细内容显示和隐藏
        minuteShow: function(index, ife) {
            if ($('#Glossary').find('.content').find('li').eq(index).find('i').hasClass('bi-caret-up-fill')) {
                $('#Glossary').find('.content').find('li').eq(index).find('i').addClass('bi-caret-down-fill')
                $('#Glossary').find('.content').find('li').eq(index).find('i').removeClass('bi-caret-up-fill')
            } else {
                $('#Glossary').find('.content').find('li').eq(index).find('i').addClass('bi-caret-up-fill')
                $('#Glossary').find('.content').find('li').eq(index).find('i').removeClass('bi-caret-down-fill')
            }
            if (ife) {
                $('#Glossary').find('.content').find('li').eq(index).find('p').slideToggle();
            } else {
                let win = $(window)
                setTimeout(function() {
                    $('#Glossary').find('.content').find('li').eq(index).find('p').slideToggle();
                    win.scrollTop($('#Glossary').find('.content').find('li').eq(index).offset().top)
                }, 10);
            }

        },
        // 出现combobox
        ariseCombobox: function(id, index) {
            let _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function(event, ui) {
                    _this.relevantData(this.value.split(',')[0].split('')[0], this.value.split(',')[1])
                    _this.minuteShow(this.value.split(',')[2])
                }
            });
            $(id).parent().find('input').attr('placeholder', 'Type to Search')
            this.$$.inputEmpty($(id).parent())
        },
    }
};